import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
  "path": "/Frequently_Asked_Question/OpenHab3_IpCamera_Binding/",
  "dateChanged": "2022-12-13",
  "author": "Mike Polinowski",
  "excerpt": "Using the OpenHab IPCamera Binding to connect your camera.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHab3 IpCamera Binding with an WQHD Camera' dateChanged='2022-12-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='Using the OpenHab IPCamera Binding to connect your camera.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_IpCamera_Binding/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_IpCamera_Binding/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab3-ipcamera-binding-with-an-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab3-ipcamera-binding-with-an-wqhd-camera",
        "aria-label": "openhab3 ipcamera binding with an wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHab3 IpCamera Binding with an WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I am using OpenHab in Docker and tried to add my camera using the `}<a parentName="p" {...{
        "href": "https://openhab-next.netlify.app/addons/bindings/ipcamera/"
      }}>{`ipcamera binding`}</a>{`. But I am unable to access the video stream or snapshot. Even though the binding shows that my camera is connected.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: First make sure that your OpenHab installation is using the correct `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/OpenHab3_Docker_IpCamera/"
      }}>{`network interface`}</a>{` to be able to communicate with your camera.`}</p>
    <p>{`Secondly, make sure that the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/openhab/openhab/tags"
      }}>{`Docker container`}</a>{` has FFMPEG installed. To install a package inside the container you need to either find the container ID or name - in the following example those are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`47b018eec7ed`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` - I will be using the latter in following commands:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`

CONTAINER ID   IMAGE                              NAMES
47b018eec7ed   openhab/openhab:milestone-alpine   openhab`}</code></pre></div>
    <p>{`Now you can execute a command inside your OpenHab container to install FFMPEG. For an Alpine container this is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apk add ffmpeg`}</code>{` and for a Debian based container use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt install ffmpeg`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti openhab apk `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` ffmpeg`}</code></pre></div>
    <p>{`Verify that the installation was successful by running the following commands:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti openhab `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /usr/bin/ffmpeg
-rwxr-xr-x    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root     root        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`272472`}</span>{` Sep `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:39 /usr/bin/ffmpeg

docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti openhab ffmpeg -h
ffmpeg version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5.1`}</span>{`.2 Copyright `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2000`}</span>{`-2022 the FFmpeg developers`}</code></pre></div>
    <p>{`Now you can use the `}<strong parentName="p">{`INSTAR Camera with API`}</strong>{` preset provided by the camera binding to add your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/77abb/OpenHab3_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAByElEQVQoz12Sy47bIBSG8wJVDDEGczgcwMZ2gj2bTKSqI8161O66qtSnqNRVn74Ccms/IRkvPvGfy24cx3me13Ulaz1oY4z3fhxH5zyRW7ftcrmcz+dlmYnIFay1prALQ4zTcjytvTb7JkNEwzD2vdYanPMxTs55AINIiGStk1I1TcMY272mabYKD5+wY5wfiuy2bTsejyklRKsyvVK9lEqIToiubVte2G0//kzff+uvv+D9J2/2jHMpJRF570MIxhgAyBkAlFKc87uZ5enLt+HzB10+cHvjrOGcd12nteac7/d7xljzhFISAB4vp3mcBzc6dJgFxpgxZppmAKO17ntdooqu0LaibcXhcJNPp9OyLMMwEFGVrbUxTgAGILcUEY3BWnZBCyGu8kshxniXEdE5V8p8UFNkVYOUijGW5XVdU0plsO4WG+tsjMHng4illty5qxxCqFtxfxkAvK99zn4N3/daSiWlAjAPeSg8y4g4TRMRWZtPXaz6i4gxRmupaZr/ZVaQUjpHdbBKqe6GLLRty8pEs+y9CyH8W7NZ142IpMy7JUSeTaVc8rSu8vn8mtJaisR77JSS9740yRaofhBtCEFrXWv+C77UW/moCaEfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a5b54eb45012ee21b8cd96de0593a7e/e4706/OpenHab3_WQHD_Cameras_01.avif 230w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/d1af7/OpenHab3_WQHD_Cameras_01.avif 460w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/7f308/OpenHab3_WQHD_Cameras_01.avif 920w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/1537c/OpenHab3_WQHD_Cameras_01.avif 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a5b54eb45012ee21b8cd96de0593a7e/a0b58/OpenHab3_WQHD_Cameras_01.webp 230w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/bc10c/OpenHab3_WQHD_Cameras_01.webp 460w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/966d8/OpenHab3_WQHD_Cameras_01.webp 920w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/5b044/OpenHab3_WQHD_Cameras_01.webp 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a5b54eb45012ee21b8cd96de0593a7e/81c8e/OpenHab3_WQHD_Cameras_01.png 230w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/08a84/OpenHab3_WQHD_Cameras_01.png 460w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/c0255/OpenHab3_WQHD_Cameras_01.png 920w", "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/77abb/OpenHab3_WQHD_Cameras_01.png 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a5b54eb45012ee21b8cd96de0593a7e/c0255/OpenHab3_WQHD_Cameras_01.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The binding will work out-of-the-box but you can also make some adjustments:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f005169ee683f70e7cd4425e0f2542b9/cd78c/OpenHab3_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABMUlEQVQY013Py07DMBAF0KyRaG0ax/a8nDh2Sh5VFwiBxII1AgnEB/AN7Pr7kLRFwNFdeDFX4ym6ruv7PqVcE6amDiE0IoIcQmBmIp6mKcZIRHwmIq1wDlwMwziMY5ezdY5YAnHbx7hPCAgLxNPjDEWCB3AeihodVhuyJSEiUuC6ux2uH3fgkIgR5+X/0jQtsxBxcfP8Ifcv9cOblVartQeopYEKEGmZi3XdiARE+on3frMo0vvBPX3C68HE3fryQiSM08Qiy43Stum45JhjGQCuFsWYpKUqi7WmVEp57/f7fc7ZLMqyNL9UM2uMOZX7cYptTnlrrVNKOTeXt9utc97+VVkLMB/jvT+Vh2GIMaaUrLVKKWNMsyjL+SNaa6V1qXW/Xt+tVtkYYG5cpbX+Ln8BQDs2/3Ffq5AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f005169ee683f70e7cd4425e0f2542b9/e4706/OpenHab3_WQHD_Cameras_02.avif 230w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/d1af7/OpenHab3_WQHD_Cameras_02.avif 460w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/7f308/OpenHab3_WQHD_Cameras_02.avif 920w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/2adae/OpenHab3_WQHD_Cameras_02.avif 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f005169ee683f70e7cd4425e0f2542b9/a0b58/OpenHab3_WQHD_Cameras_02.webp 230w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/bc10c/OpenHab3_WQHD_Cameras_02.webp 460w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/966d8/OpenHab3_WQHD_Cameras_02.webp 920w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/5b1dd/OpenHab3_WQHD_Cameras_02.webp 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f005169ee683f70e7cd4425e0f2542b9/81c8e/OpenHab3_WQHD_Cameras_02.png 230w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/08a84/OpenHab3_WQHD_Cameras_02.png 460w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/c0255/OpenHab3_WQHD_Cameras_02.png 920w", "/en/static/f005169ee683f70e7cd4425e0f2542b9/cd78c/OpenHab3_WQHD_Cameras_02.png 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f005169ee683f70e7cd4425e0f2542b9/c0255/OpenHab3_WQHD_Cameras_02.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The binding will work out-of-the-box but you can also make some adjustments - for example, the binding will preselect the `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Aussenkameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`video and snapshot channel`}</a>{` it is going to use. Be careful when changing the ffmpeg input URL - the binding will be using the second (Full HD) stream here instead of the first (WQHD). This is done due to the fact that the highest resolution is streamed encoded in h.265 instead of h.264 which will cause issues:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ee0319eda101302eb7863964b4947f84/1628f/OpenHab3_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABgElEQVQoz02RTW4bMQyF5wSJNNJY/6JEaTTjjJMAhrspkF3QRVE0CHKBdtMCRe+/LiTZrh8IiptPfCQHRMx5Xtd9jDiDT4gRIEAACN6DtS6nvG2btc45fxYAAqwRhrIs2+Hw9PSMiHyahJRzwpxSg71znYFeGGO01t7DTgg2TcM+x2CEnkYrGBvHe0IChGVZsCohppRyznPP3lfeGMsYGykdPr3/ev76I7x8xM9vXGhKSHemlNZaK6VVVc1Syl6XUna7HaV0WL//Ld/+uC+//etPphy5v4utoTG2W7W2mtdaS1l5KaVzjnNe4a3EffLZSXSKM0YoDSEgYrfXhjSX2lpr26ee86nBh8f9w4YpY8r9P+dcjHjtI0R/1U3IOvM4Dg9NOee6bc4JIYhYylK7WNcuVHOf+cqf4ePxeDqdSikdppR6D95DXZc2t6FUHVsIOc+XheWmlNJt52VZQ4gxYoz1YDHGdmTbtq3+z5wuusIhhHkuIVSuZ4BgzjrvrNv+B8WgTXVV2yX6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee0319eda101302eb7863964b4947f84/e4706/OpenHab3_WQHD_Cameras_03.avif 230w", "/en/static/ee0319eda101302eb7863964b4947f84/d1af7/OpenHab3_WQHD_Cameras_03.avif 460w", "/en/static/ee0319eda101302eb7863964b4947f84/7f308/OpenHab3_WQHD_Cameras_03.avif 920w", "/en/static/ee0319eda101302eb7863964b4947f84/a0c3b/OpenHab3_WQHD_Cameras_03.avif 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ee0319eda101302eb7863964b4947f84/a0b58/OpenHab3_WQHD_Cameras_03.webp 230w", "/en/static/ee0319eda101302eb7863964b4947f84/bc10c/OpenHab3_WQHD_Cameras_03.webp 460w", "/en/static/ee0319eda101302eb7863964b4947f84/966d8/OpenHab3_WQHD_Cameras_03.webp 920w", "/en/static/ee0319eda101302eb7863964b4947f84/46ece/OpenHab3_WQHD_Cameras_03.webp 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee0319eda101302eb7863964b4947f84/81c8e/OpenHab3_WQHD_Cameras_03.png 230w", "/en/static/ee0319eda101302eb7863964b4947f84/08a84/OpenHab3_WQHD_Cameras_03.png 460w", "/en/static/ee0319eda101302eb7863964b4947f84/c0255/OpenHab3_WQHD_Cameras_03.png 920w", "/en/static/ee0319eda101302eb7863964b4947f84/1628f/OpenHab3_WQHD_Cameras_03.png 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ee0319eda101302eb7863964b4947f84/c0255/OpenHab3_WQHD_Cameras_03.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The binding then offers a wide range of items that you can use in OpenHab (see also `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
      }}>{`OpenHAB v3 Configuration for your WQHD INSTAR MQTTv5 Broker`}</a>{` for even more options):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/4c7c9/OpenHab3_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABAElEQVQY0z2Qa46DMAyEuUf9ip2EUpUWBCwJFNq9/6VWbCokyz9sf6PxVOM4PR5PIhZxzELEALiur2mczLyIE3GA3DX+vSSnHgABEJEAsJrnFEIUcaqmas4pIj2fXQjROS2FxLXpmlN9bZgFkUpVOS9d15cjZmEWAGyam5kv8gBHJ5aUlrZ9iLhyecDbtg/DWAx/JYmn6aeur+cEAFVt3/emuSESEZdVlVIehtH7EEL0PqhajPVr270PdBhxxYuq5byEEE+YiKu+78+3S1pObU7Z+yDMiN9sxNmclvu9ZZaS4gHnvHw+v0XyNHnkzEJ2BVcD4OVyUYuv7d3+w4Vnlj9vhDw+ryS5pAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/e4706/OpenHab3_WQHD_Cameras_04.avif 230w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/d1af7/OpenHab3_WQHD_Cameras_04.avif 460w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/7f308/OpenHab3_WQHD_Cameras_04.avif 920w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/e1c99/OpenHab3_WQHD_Cameras_04.avif 1380w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/76ea5/OpenHab3_WQHD_Cameras_04.avif 1840w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/ab00b/OpenHab3_WQHD_Cameras_04.avif 2145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/a0b58/OpenHab3_WQHD_Cameras_04.webp 230w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/bc10c/OpenHab3_WQHD_Cameras_04.webp 460w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/966d8/OpenHab3_WQHD_Cameras_04.webp 920w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/445df/OpenHab3_WQHD_Cameras_04.webp 1380w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/78de1/OpenHab3_WQHD_Cameras_04.webp 1840w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/a986e/OpenHab3_WQHD_Cameras_04.webp 2145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/81c8e/OpenHab3_WQHD_Cameras_04.png 230w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/08a84/OpenHab3_WQHD_Cameras_04.png 460w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/c0255/OpenHab3_WQHD_Cameras_04.png 920w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/b1001/OpenHab3_WQHD_Cameras_04.png 1380w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/161ec/OpenHab3_WQHD_Cameras_04.png 1840w", "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/4c7c9/OpenHab3_WQHD_Cameras_04.png 2145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1a105f239b15c9ab7dbef945a9f3fe3c/c0255/OpenHab3_WQHD_Cameras_04.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Under `}<strong parentName="p">{`Channels`}</strong>{` we can now select what we want to use in OpenHab by binding those `}<strong parentName="p">{`Channels`}</strong>{` to `}<strong parentName="p">{`Items`}</strong>{` - I will choose the `}<strong parentName="p">{`MJPEG URL`}</strong>{` that I am going to use to display the camera's live video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4afc913611c812de3f33e85920cf3809/5148a/OpenHab3_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABsklEQVQoz3WQyY4TQRBE/QO2e0W4srL2pbtd2NX2jIXH6rI0HABxmAsSv8CBMwe+HnUbLxdCoVJeXkVkzpxzIYS+j845DoQzlFIqpcZ3GrrGb7pOKaW1FkJwzoWUgqHmOJNKK220sch4UZRlWRKyYgwJIQAAlFJCKFkBACGEUmqMYYwVZVmU1Wz/oW2NYO8rTt7VVZXlOQCEEGKMTdMgIhdCaQ2T3CgPAMWkWfjxp/3+m3z9RT//rEBky4W1/nQ6pZSappVybKuUvi6hERmltCzLEbbDmx3e8PCNHb7UK1jM5ymdX18/vbycvG9u2IWcBs3GBafktdetVZqBZrSuquUy2/W7lM4pnWPsb+TjF3e4W4duvVbaKG3qui7ynCE7Ho/DkGLsjbEPmaOn2vivdghhs9k455RSdV3Ps1w5n4b0/HwwxlGKeDGymwGuO8cY+z5aa7VSZV3jYnn07uNw2u+e7rHX5Evs/WBq0nTSEYYsCwx911hjL4elQqPyiBwRJxLvtfVV19rZehvP6bzdRimVEFJIxbl4rP1fOM+yxvv9/qltW86FEJJzAUDhQYTABf4LTulegS5D0DAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4afc913611c812de3f33e85920cf3809/e4706/OpenHab3_WQHD_Cameras_05.avif 230w", "/en/static/4afc913611c812de3f33e85920cf3809/d1af7/OpenHab3_WQHD_Cameras_05.avif 460w", "/en/static/4afc913611c812de3f33e85920cf3809/7f308/OpenHab3_WQHD_Cameras_05.avif 920w", "/en/static/4afc913611c812de3f33e85920cf3809/9dc19/OpenHab3_WQHD_Cameras_05.avif 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4afc913611c812de3f33e85920cf3809/a0b58/OpenHab3_WQHD_Cameras_05.webp 230w", "/en/static/4afc913611c812de3f33e85920cf3809/bc10c/OpenHab3_WQHD_Cameras_05.webp 460w", "/en/static/4afc913611c812de3f33e85920cf3809/966d8/OpenHab3_WQHD_Cameras_05.webp 920w", "/en/static/4afc913611c812de3f33e85920cf3809/d565e/OpenHab3_WQHD_Cameras_05.webp 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4afc913611c812de3f33e85920cf3809/81c8e/OpenHab3_WQHD_Cameras_05.png 230w", "/en/static/4afc913611c812de3f33e85920cf3809/08a84/OpenHab3_WQHD_Cameras_05.png 460w", "/en/static/4afc913611c812de3f33e85920cf3809/c0255/OpenHab3_WQHD_Cameras_05.png 920w", "/en/static/4afc913611c812de3f33e85920cf3809/5148a/OpenHab3_WQHD_Cameras_05.png 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4afc913611c812de3f33e85920cf3809/c0255/OpenHab3_WQHD_Cameras_05.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Simply accept the default settings here to create a `}<strong parentName="p">{`New Item`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3a87f5182a49571406c6225725653a44/1628f/OpenHab3_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABQElEQVQY002Ry27bMBBF/QU2Lcl8DGfI4cOiJEqGkx9okSDrZN0CaddF/38dhIbTHtwNFxdnOLNj5lLKsiwxZQYdHIK1cAcRa63btoUQAMDewcaOQyzTPC81piSO3eFwKKVcLhdjjLVojJVSSSmV0lpr1ZBSdY3d41rmzCg7Ur0ceiEEhzBNcwixlDKOo/dM5IgIwGpttDZKma7rP8vr29/19Q8+/XbPv04UxX4fvC+lTNMEAMNwamZ1R7fczfH5Pb+82+8/6duPAaM47LOjWmtdN+c8gCVyiJ/a/9P3zTxnXjInMtmBPA1CiBCjc14pDWBjTLVW79la/EqMaRiG4/G4m+ZlqWtMKeUspRRCpJjO57H90zFH5oBItyeRc863cjNv2+V6vY7jmFK6lRGRObRVw8PD4/k8thv9MxO529gfFHU5WwUHnSIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a87f5182a49571406c6225725653a44/e4706/OpenHab3_WQHD_Cameras_06.avif 230w", "/en/static/3a87f5182a49571406c6225725653a44/d1af7/OpenHab3_WQHD_Cameras_06.avif 460w", "/en/static/3a87f5182a49571406c6225725653a44/7f308/OpenHab3_WQHD_Cameras_06.avif 920w", "/en/static/3a87f5182a49571406c6225725653a44/a0c3b/OpenHab3_WQHD_Cameras_06.avif 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3a87f5182a49571406c6225725653a44/a0b58/OpenHab3_WQHD_Cameras_06.webp 230w", "/en/static/3a87f5182a49571406c6225725653a44/bc10c/OpenHab3_WQHD_Cameras_06.webp 460w", "/en/static/3a87f5182a49571406c6225725653a44/966d8/OpenHab3_WQHD_Cameras_06.webp 920w", "/en/static/3a87f5182a49571406c6225725653a44/46ece/OpenHab3_WQHD_Cameras_06.webp 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a87f5182a49571406c6225725653a44/81c8e/OpenHab3_WQHD_Cameras_06.png 230w", "/en/static/3a87f5182a49571406c6225725653a44/08a84/OpenHab3_WQHD_Cameras_06.png 460w", "/en/static/3a87f5182a49571406c6225725653a44/c0255/OpenHab3_WQHD_Cameras_06.png 920w", "/en/static/3a87f5182a49571406c6225725653a44/1628f/OpenHab3_WQHD_Cameras_06.png 1232w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3a87f5182a49571406c6225725653a44/c0255/OpenHab3_WQHD_Cameras_06.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And repeat those steps with the `}<strong parentName="p">{`Image URL`}</strong>{` that can be used as a thumbnail for our camera on the OpenHab Dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/c929c/OpenHab3_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABuElEQVQoz12SSW/bMBBG9QscLTSMWuRwFlKLtViJk9SRbcku0F566qm9Neiht/7/a2EpboEM3onAI+ebYeC9z/O8qioREZOiBUQCAGMMIlrEqiy6emMRia7nAIBEYqEgCMR5n+XlpgKLcaKUUojYNE3btlVVMbMXyTOPRIiYZZlz3mij1DJRy2DXlBnqdBnpVaKSOAxDZt7vX47HIc8La5GIiQWREIlZAGyapkkcx3EUbL/9Kb7+1p9ezfBDrdZ3i8XlfBnH836/z7L8at5gFiKeQtkkueqBfPnFn1/Ty099+p6s1uHdou/7YRgPh2NdN1NSfneFtfgmVxkXYp35wGatkiSKIkTq+8PpNOx2j855ZhFxzDIDYLXWN7mui3LDIiyilIqiyDl3OByfnz96nxkD79AaNNxebqby3hORUioMw23Xnc+XrrsXcfOc5oYRyWijjYW8nQYWB113v91uvffMrJSKw7Br23E8Pzzs/nU7bx7AAlhrCaR8k733zjkRIaJEqWqxeHHSD+PT45PWZkLrNE1TbQxYi4j0PzNPNctKqSSKgKCoNkVezh/DusJmNZKgRXtjlv8CtCdZJ5vwUQYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b97574d0b647bc5031a63c2acfa5a8b/e4706/OpenHab3_WQHD_Cameras_07.avif 230w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/d1af7/OpenHab3_WQHD_Cameras_07.avif 460w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/7f308/OpenHab3_WQHD_Cameras_07.avif 920w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/203bb/OpenHab3_WQHD_Cameras_07.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1b97574d0b647bc5031a63c2acfa5a8b/a0b58/OpenHab3_WQHD_Cameras_07.webp 230w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/bc10c/OpenHab3_WQHD_Cameras_07.webp 460w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/966d8/OpenHab3_WQHD_Cameras_07.webp 920w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/491b1/OpenHab3_WQHD_Cameras_07.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b97574d0b647bc5031a63c2acfa5a8b/81c8e/OpenHab3_WQHD_Cameras_07.png 230w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/08a84/OpenHab3_WQHD_Cameras_07.png 460w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/c0255/OpenHab3_WQHD_Cameras_07.png 920w", "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/c929c/OpenHab3_WQHD_Cameras_07.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1b97574d0b647bc5031a63c2acfa5a8b/c0255/OpenHab3_WQHD_Cameras_07.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now be able to see both the MJPEG and Image URL under `}<strong parentName="p">{`Model`}</strong>{`. Those URLs can be used to access your camera's live stream and snapshot - you can test them by opening them in your web browser:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/530772b4e2d22ab5c499a22fa7300e84/8d68c/OpenHab3_WQHD_Cameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABLUlEQVQY02XLy07DMBBA0fxDQmuPZzx+xHXsJqQpzaNKJQRii8QCfoUV8O1IvDZIZ3E3twghpJT6vve1T97mXe29D6H+Vtf+OAynafJNNHVtQ/jx1YVzLoQQY9SsiU3b9d7Xmg0by8YaY5XmtBWrwoV5RpqQFq3PzDNAcRyGJkYFQKQ5j+3yYLuzSiPmCfNEeYbm1FebFmk+3tyvl9vlfHder/ft4WpTDOPSDaPbJR8zsVWoFTH+ImKJuquqcLUJbOb+cNN2Y9exwsNWFPblwzy/b5/e4PEVfUYARI1I3whJgnJSrsYchGjLcl+W7ZemLIt0fYxtb3fZxUya/7Y/AAoUjuvltCzaOoEkSaNmqbDIqUlNdNZ4Z4n0/1kprKpqI5QQEiRICUKCASAhPgHUvjtXS7L95wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/530772b4e2d22ab5c499a22fa7300e84/e4706/OpenHab3_WQHD_Cameras_08.avif 230w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/d1af7/OpenHab3_WQHD_Cameras_08.avif 460w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/7f308/OpenHab3_WQHD_Cameras_08.avif 920w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/e83e9/OpenHab3_WQHD_Cameras_08.avif 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/530772b4e2d22ab5c499a22fa7300e84/a0b58/OpenHab3_WQHD_Cameras_08.webp 230w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/bc10c/OpenHab3_WQHD_Cameras_08.webp 460w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/966d8/OpenHab3_WQHD_Cameras_08.webp 920w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/5dcdf/OpenHab3_WQHD_Cameras_08.webp 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/530772b4e2d22ab5c499a22fa7300e84/81c8e/OpenHab3_WQHD_Cameras_08.png 230w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/08a84/OpenHab3_WQHD_Cameras_08.png 460w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/c0255/OpenHab3_WQHD_Cameras_08.png 920w", "/en/static/530772b4e2d22ab5c499a22fa7300e84/8d68c/OpenHab3_WQHD_Cameras_08.png 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/530772b4e2d22ab5c499a22fa7300e84/c0255/OpenHab3_WQHD_Cameras_08.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To display the live video I want to use the `}<a parentName="p" {...{
        "href": "https://community.openhab.org/t/clickable-camera-widget/110069"
      }}>{`Official Camera Widget`}</a>{`. Go to `}<strong parentName="p">{`Developer Tools`}</strong>{` and click to create a new widget:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/8d68c/OpenHab3_WQHD_Cameras_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA+0lEQVQY02XPQU7DMBAF0OwRle3YnnFax+OSONWkKYK0grSIE9AFG9ZcgQ0St0d1yYonzeZL/0tTpKxp2rVfpXUMRHeR2kiRKITQdd04jikl731d15TDelZsh4GZu64rtUF0AOgQagQEC1l5oU2GiNZaMyvCEiujVqCtLoUQSqnycqWxVimVE6nzgJRSCCGlkLNi//ZJ05lO7xBasbjFyh9fXg/7kZlDIATApb8/TMdpijGuMgBQWcEfP/X5y52/bfsgFzfG+fHpND1PzD1z37YJsLLgKueuX1gArfVf+XGbNnHZeECrpRQGXNr0zNxvh2HYEZHWWv5zLf8CHOEp5nd+r8sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/e4706/OpenHab3_WQHD_Cameras_09.avif 230w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/d1af7/OpenHab3_WQHD_Cameras_09.avif 460w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/7f308/OpenHab3_WQHD_Cameras_09.avif 920w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/e83e9/OpenHab3_WQHD_Cameras_09.avif 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/a0b58/OpenHab3_WQHD_Cameras_09.webp 230w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/bc10c/OpenHab3_WQHD_Cameras_09.webp 460w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/966d8/OpenHab3_WQHD_Cameras_09.webp 920w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/5dcdf/OpenHab3_WQHD_Cameras_09.webp 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/81c8e/OpenHab3_WQHD_Cameras_09.png 230w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/08a84/OpenHab3_WQHD_Cameras_09.png 460w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/c0255/OpenHab3_WQHD_Cameras_09.png 920w", "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/8d68c/OpenHab3_WQHD_Cameras_09.png 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2416d3cb8f49d721b81ee0c5830f7a6a/c0255/OpenHab3_WQHD_Cameras_09.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy the widget code from (see link above - or code at the end of this article), paste it in and click save:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62c32d84c5594fac00fcffbaef2925e1/b0aa6/OpenHab3_WQHD_Cameras_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA+ElEQVQY013NXU6DQBSGYZbQUhJgmDMDFM7MmR/57cTS6l0XYOICXIleum0DWmN88t2++aJ2c2yOTSkcYoutVqgQq6omsiGEcaMUCiGklHzTCl4Bj5xz1lpEVdX11A3ncRr7bhisd9o6ZQwKAVmWFwXnHADgcDjEcbzfFomyzhnPC1CoDNF1Ds/hFKYHa/U2qqoSQAIIgPU7SZL4LlrmHsuiFbnRLZFZxtPlNI+9sVY5p70nKSFN0+zu+/kn9m+f/PUjfXnXjzeDzTKFJcydd0RkzDrG2G6329/Ff0Td9dYMT9KfyfVE+jKH6xpb56x31hjDGPvX/MZfuOYyCKMvj6YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62c32d84c5594fac00fcffbaef2925e1/e4706/OpenHab3_WQHD_Cameras_10.avif 230w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/d1af7/OpenHab3_WQHD_Cameras_10.avif 460w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/7f308/OpenHab3_WQHD_Cameras_10.avif 920w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/e5dbf/OpenHab3_WQHD_Cameras_10.avif 1233w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62c32d84c5594fac00fcffbaef2925e1/a0b58/OpenHab3_WQHD_Cameras_10.webp 230w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/bc10c/OpenHab3_WQHD_Cameras_10.webp 460w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/966d8/OpenHab3_WQHD_Cameras_10.webp 920w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/e96a9/OpenHab3_WQHD_Cameras_10.webp 1233w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62c32d84c5594fac00fcffbaef2925e1/81c8e/OpenHab3_WQHD_Cameras_10.png 230w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/08a84/OpenHab3_WQHD_Cameras_10.png 460w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/c0255/OpenHab3_WQHD_Cameras_10.png 920w", "/en/static/62c32d84c5594fac00fcffbaef2925e1/b0aa6/OpenHab3_WQHD_Cameras_10.png 1233w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62c32d84c5594fac00fcffbaef2925e1/c0255/OpenHab3_WQHD_Cameras_10.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To add the widget to your dashboard go to pages and click on the `}<strong parentName="p">{`+`}</strong>{` (on a fresh page you need to create a block, row and column first):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aa4bfbfdce57300851814c7c5fdb0547/0d0e4/OpenHab3_WQHD_Cameras_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABaklEQVQoz4XQO27cMBAGYJ5gJYrU8CG+RA4prbgre404iNPkAinSpMk9UgYpcvFAMiK48CIfpiBA/DNDkvP5XGtd12vOJTobx9F7H0NIY/BhM0/TsiwxRmOMtdZ7Z6111qK3BBFzzqUU51zHOABIpbSSRsntoLW1VkoJAEJIIaRSSkrZ933HOKn1cnTljDVNkzEjZue81gNiXpYawrjfO2udGYzzo1KKUkpeXj7fbreEmBA5523bTtP8/PwRMb8GhsEcyS1sbE3joFRLKanneS4Yg4/Bc87ato0xXS5X78MwmCPwWtZYqfSXh1pSPJ1OpPz447//Ft9+ya8/wYSeM621Ump74z1KCyEAgMy3T3n9EJbHMK8gto/pOWf/03UdgCDXulzrgmmMo+e77g3a3SWEIOvDBjHHmACAMcb/6TkXe71rW3sqZSolpRRjBABK6TFTdd1T0zy2Lev7d4eTuDvCb3szzule9/wF6NhHgZeJkAwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa4bfbfdce57300851814c7c5fdb0547/e4706/OpenHab3_WQHD_Cameras_11.avif 230w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/d1af7/OpenHab3_WQHD_Cameras_11.avif 460w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/7f308/OpenHab3_WQHD_Cameras_11.avif 920w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/198fd/OpenHab3_WQHD_Cameras_11.avif 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aa4bfbfdce57300851814c7c5fdb0547/a0b58/OpenHab3_WQHD_Cameras_11.webp 230w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/bc10c/OpenHab3_WQHD_Cameras_11.webp 460w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/966d8/OpenHab3_WQHD_Cameras_11.webp 920w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/fc6cc/OpenHab3_WQHD_Cameras_11.webp 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa4bfbfdce57300851814c7c5fdb0547/81c8e/OpenHab3_WQHD_Cameras_11.png 230w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/08a84/OpenHab3_WQHD_Cameras_11.png 460w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/c0255/OpenHab3_WQHD_Cameras_11.png 920w", "/en/static/aa4bfbfdce57300851814c7c5fdb0547/0d0e4/OpenHab3_WQHD_Cameras_11.png 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aa4bfbfdce57300851814c7c5fdb0547/c0255/OpenHab3_WQHD_Cameras_11.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This opens a menu that should now list the widget you created earlier:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4092553dce52a9eb15198f53719f0e43/2ba99/OpenHab3_WQHD_Cameras_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA3ElEQVQY01XP207DMAyA4d42zYk2TRrnZHtaS9kE4gF4/9dCG1CVT76Lftnp5j/OOaWUEEPOmZkJkfYP2u6EyMwxRiHEcCKl7I54miallJSylHJhJr7Q2ye9vhMRMwPAT3CiuvNm+/ACOSfE0hqud7zurVZEBABjHq/HGGP/xcYYbQwBfAHsteF24/VWamutxhi11uZEa90552b3G8thEELEZdlSwlyA1kTXnHMpxXt//Lnve/HUNUSI4Jwbx9FOs51cCCEAhBhrSgXSEhcA8N6b593W2pxzCMF7/w2Yvx8jojpQ5AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4092553dce52a9eb15198f53719f0e43/e4706/OpenHab3_WQHD_Cameras_12.avif 230w", "/en/static/4092553dce52a9eb15198f53719f0e43/d1af7/OpenHab3_WQHD_Cameras_12.avif 460w", "/en/static/4092553dce52a9eb15198f53719f0e43/7f308/OpenHab3_WQHD_Cameras_12.avif 920w", "/en/static/4092553dce52a9eb15198f53719f0e43/eac99/OpenHab3_WQHD_Cameras_12.avif 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4092553dce52a9eb15198f53719f0e43/a0b58/OpenHab3_WQHD_Cameras_12.webp 230w", "/en/static/4092553dce52a9eb15198f53719f0e43/bc10c/OpenHab3_WQHD_Cameras_12.webp 460w", "/en/static/4092553dce52a9eb15198f53719f0e43/966d8/OpenHab3_WQHD_Cameras_12.webp 920w", "/en/static/4092553dce52a9eb15198f53719f0e43/0fff5/OpenHab3_WQHD_Cameras_12.webp 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4092553dce52a9eb15198f53719f0e43/81c8e/OpenHab3_WQHD_Cameras_12.png 230w", "/en/static/4092553dce52a9eb15198f53719f0e43/08a84/OpenHab3_WQHD_Cameras_12.png 460w", "/en/static/4092553dce52a9eb15198f53719f0e43/c0255/OpenHab3_WQHD_Cameras_12.png 920w", "/en/static/4092553dce52a9eb15198f53719f0e43/2ba99/OpenHab3_WQHD_Cameras_12.png 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4092553dce52a9eb15198f53719f0e43/c0255/OpenHab3_WQHD_Cameras_12.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click to configure the empty widget:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/944a9963eb196bd60b699280d2a66423/d8817/OpenHab3_WQHD_Cameras_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAzklEQVQY022N3UoDMRBGc2MvLGw2f5PJbJLaNDFoqGz7EOKVvocg4vvfyEa6CO3hwHzD8DEsv3+Xjx/3+klvX7bMoCWABQCttbkFdI0xiMhaeWh5V4LNwaJR2hillOyoW4xKjf0EAGw+nefT+em5pUOevLcX0Dl5xSjlXogohdRaScmOx5fWWiklpRTjLoS4el0WSkbOs3NIxIeB1fqYUjp0vA8XI9H0r6XWMYjl7WZzd7/dslrrWiaaEJ1z1HW4YJE8+v0S/nawRAQAnPNfWI0kSeGo2ncAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/944a9963eb196bd60b699280d2a66423/e4706/OpenHab3_WQHD_Cameras_13.avif 230w", "/en/static/944a9963eb196bd60b699280d2a66423/d1af7/OpenHab3_WQHD_Cameras_13.avif 460w", "/en/static/944a9963eb196bd60b699280d2a66423/7f308/OpenHab3_WQHD_Cameras_13.avif 920w", "/en/static/944a9963eb196bd60b699280d2a66423/2b18a/OpenHab3_WQHD_Cameras_13.avif 1238w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/944a9963eb196bd60b699280d2a66423/a0b58/OpenHab3_WQHD_Cameras_13.webp 230w", "/en/static/944a9963eb196bd60b699280d2a66423/bc10c/OpenHab3_WQHD_Cameras_13.webp 460w", "/en/static/944a9963eb196bd60b699280d2a66423/966d8/OpenHab3_WQHD_Cameras_13.webp 920w", "/en/static/944a9963eb196bd60b699280d2a66423/e8403/OpenHab3_WQHD_Cameras_13.webp 1238w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/944a9963eb196bd60b699280d2a66423/81c8e/OpenHab3_WQHD_Cameras_13.png 230w", "/en/static/944a9963eb196bd60b699280d2a66423/08a84/OpenHab3_WQHD_Cameras_13.png 460w", "/en/static/944a9963eb196bd60b699280d2a66423/c0255/OpenHab3_WQHD_Cameras_13.png 920w", "/en/static/944a9963eb196bd60b699280d2a66423/d8817/OpenHab3_WQHD_Cameras_13.png 1238w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/944a9963eb196bd60b699280d2a66423/c0255/OpenHab3_WQHD_Cameras_13.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And paste in the Image (Thumbnail) and MJPEG (Stream) URL from the `}<strong parentName="p">{`Model`}</strong>{` menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df4344e62b1068712287880f25c58997/d9b5d/OpenHab3_WQHD_Cameras_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABg0lEQVQoz32S22rdMBBF/Xwsa2YkWRpdfJElx4455CFQCnkOlPb/P6j4OCEtlC72k2ChPcM01toY4ziOjlkTaq1IKaNUrz5wzjGzUgoRrxck0op6RY3W2jxAxFYIKSURERLhCRFprQERAABRAsIZKYRohWjmnGNK+gEAeOYQArMvpR7Hcb/f930PIYYQphi0OnuR1qfctk2KIQVvje6NAoRt32tdl5NSSs15CSH5T9Rjosn17SW7sbhhMWFWfgSkbdvWda21lnKm1nUcJ+/PLt57RFJET2MSXXfK/esv8/pTvvyA+zsYt9aalyXnvCzl+n2a5muQ3rLoOiG622M1UsrGswve9UYZrUDKkpfn49i2Pc+Z2TP7YRjGaZqm8WUtANC2N/Gg67omppTS4BxfCy9lnec8DMNlfsXxtzoBwO12a9v2Qz7dFK21xhildClnW+fYWvcX1n5/mhHxki+a/hNjjNZ6359rrTHG6za+cO5ty/+TCXFIKeflHzKzdQwS5B/8BlZYO/S2hJKmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df4344e62b1068712287880f25c58997/e4706/OpenHab3_WQHD_Cameras_14.avif 230w", "/en/static/df4344e62b1068712287880f25c58997/d1af7/OpenHab3_WQHD_Cameras_14.avif 460w", "/en/static/df4344e62b1068712287880f25c58997/7f308/OpenHab3_WQHD_Cameras_14.avif 920w", "/en/static/df4344e62b1068712287880f25c58997/e37ea/OpenHab3_WQHD_Cameras_14.avif 1224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df4344e62b1068712287880f25c58997/a0b58/OpenHab3_WQHD_Cameras_14.webp 230w", "/en/static/df4344e62b1068712287880f25c58997/bc10c/OpenHab3_WQHD_Cameras_14.webp 460w", "/en/static/df4344e62b1068712287880f25c58997/966d8/OpenHab3_WQHD_Cameras_14.webp 920w", "/en/static/df4344e62b1068712287880f25c58997/caef1/OpenHab3_WQHD_Cameras_14.webp 1224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df4344e62b1068712287880f25c58997/81c8e/OpenHab3_WQHD_Cameras_14.png 230w", "/en/static/df4344e62b1068712287880f25c58997/08a84/OpenHab3_WQHD_Cameras_14.png 460w", "/en/static/df4344e62b1068712287880f25c58997/c0255/OpenHab3_WQHD_Cameras_14.png 920w", "/en/static/df4344e62b1068712287880f25c58997/d9b5d/OpenHab3_WQHD_Cameras_14.png 1224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df4344e62b1068712287880f25c58997/c0255/OpenHab3_WQHD_Cameras_14.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your settings and open the page on your dashboard:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/c26caa8e4367092dcfc2165124baca4c/OpenHab3_WQHD_Cameras_15.gif",
        "alt": "OpenHab3 IpCamera Binding with an WQHD Camera"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://community.openhab.org/t/clickable-camera-widget/110069"
      }}>{`Widget Code`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ClickableCameraImage
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example: http://192.168.1.2:8080/ipcamera/uniqueID/autofps.mjpeg"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Thumbnail URL
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` thumbnailURL
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example: http://192.168.1.2:8080/ipcamera/uniqueID/snapshots.mjpeg"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Stream URL
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` streamURL
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` item
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Select the Camera to Control
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` camera
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Show Equipment Controls
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` showSettings
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BOOLEAN
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Show Audio Alarms
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` showAudioAlarms
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BOOLEAN
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Show Motion Alarms
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` showMotionAlarms
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BOOLEAN
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` item
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Item to Switch
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switchItem
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` f7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`--f7-card-margin-horizontal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 9rem
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16rem
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`slots`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` photos
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionPhotoBrowserConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`lazy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`theme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` dark
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` popup
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionPhotos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`props.streamURL`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`lazy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 6px
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 9rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.thumbnailURL
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` f7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`content
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` toggle
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommandAlt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.switchItem
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` power
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.switchItem].state === 'ON') ? 'cyan' : 'white'"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.switchItem].state === 'ON') ? '0.4' : '0.3'"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0rem
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.switchItem `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!==`}</span>{` undefined
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` group
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionGroupPopupItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` gear_alt
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.4`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0rem
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showSettings === true
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eye
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showMotionAlarms === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'=(props.showMotionAlarms !== true) ? "0" : (items[props.camera + "_MotionAlarm"].state === "ON") ? "0.5" : "0" '`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 7.8rem
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ear
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showAudioAlarms === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'=(props.showAudioAlarms !== true) ? "0" : (items[props.camera + "_AudioAlarm"].state === "ON") ? "0.5" : "0" '`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 7.7rem`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      